<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF LOANS TRANSMITAL</h4>

        </v-toolbar-title>
      </v-toolbar>
      <v-flex xs12 class="mx-2">
        <v-layout row wrap class="align-center">
          <v-flex xs12 md4>
            <v-select
              v-model="category"
              class="mx-3 pt-6"
              :items="['Monthly']"
              label="Category"
              required
              dense
              @change="list_of_loans_not_ye_approve"
            ></v-select>
            <v-select
              v-if="category==='Monthly'"
              v-model="month_of"
              class="mx-3 pt-5"
              :items="month_of_items"
              item-value="id"
              item-text="month_of"
              label="Month Of"
              required
              dense
              @change="list_of_loans_not_ye_approve"
            ></v-select>
          </v-flex>
        </v-layout>
      </v-flex>
      Print:
      <v-icon
        class="mr-2"
        color="warning"
        @click="print_data()"
      >
        {{icons.mdiPrinter}}
      </v-icon>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class=" text-uppercase">
              No.
            </th>
            <th class=" text-uppercase">
              DATE
            </th>
            <th class="text-center text-uppercase">
              TOTAL AMOUNT
            </th>
            <th class="text-center text-uppercase">
              STATUS
            </th>
            <th class="text-center text-uppercase">
              View
            </th>
            <th class="text-center text-uppercase">
              Print
            </th>
            <th class="text-center text-uppercase" v-if="position==='ADMIN'">
              Action
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in transmital_loans_items"
            :key="item.id"
          >
            <td>{{ item.id }}</td>
            <td>
              {{ item.date }}
            </td>
            <td class="text-center">
              {{ formatPrice(total_loans_released(item)) }}
            </td>
            <td class="text-center">
              <div v-if="item.is_approved===1">
                <v-chip color="success" dark>APPROVED</v-chip>
              </div>
              <div v-else-if="item.is_approved===0">
                <v-chip color="error" dark>NOT YET DONE</v-chip>
              </div>
              <div v-else-if="item.is_approved===2">
                <v-chip color="error" dark>NOT YET RELEASED FROM FINANCE</v-chip>
              </div>
            </td>
            <td class="text-center">
              <v-icon
                class="mr-2"
                color="success"
                @click="view(item)"
              >
                {{icons.mdiEyeCircle}}
              </v-icon>
            </td>
            <td class="text-center">
              <v-icon
                class="mr-2"
                color="warning"
                @click="print_transmital(item)"
              >
                {{icons.mdiPrinter}}
              </v-icon>
            </td>
            <td class="text-center" v-if=" position==='ADMIN'">
              <div v-if="item.is_approved===2">
                <v-icon
                  class="mr-2"
                  color="error"
                  @click="for_released(item)"
                >
                  {{icons.mdiToggleSwitchOff}}
                </v-icon>
              </div>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
    <v-dialog v-model="view_dialog" max-width="80%">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF LOANS TRANSMITAL #
          {{selected_transmital_no}}</h4>

        </v-toolbar-title>
      </v-toolbar>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class=" text-uppercase">
              ID
            </th>
            <th class=" text-uppercase">
              TYPE OF LOAN
            </th>
            <th class=" text-uppercase">
              SPECIFY
            </th>
            <th class=" text-uppercase">
              NAME
            </th>
            <th class="text-center text-uppercase">
              AMOUNT
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in loans_items"
            :key="item.id"
          >
            <td>{{ item.id }}</td>
            <td>
              {{ item.type_of_loan }}
            </td>
            <td>
              {{ item.specify_type_of_loan }}
            </td>
            <td>
              {{ item.members.last_name+', '+item.members.first_name }}
            </td>
            <td class="text-center">
              {{ formatPrice(item.amount) }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-dialog>
    <v-dialog v-model="dialogs" persistent max-width="80%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">RELEASE DETAILS OF TRANSMITAL #
            {{selected_transmital_no}}</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" class="multi-col-validation mt-6">
            <v-row>
              <v-col
                md="12"
                cols="12"
              >
                <v-text-field
                  v-model="cheque_date"
                  label="Cheque Date"
                  dense
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  type="date"
                ></v-text-field>
              </v-col>
              <v-col
                md="12"
                cols="12"
              >
                <v-text-field
                  v-model="cheque_no"
                  label="Cheque #"
                  dense
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-show="alert">
                <v-alert
                  color="warning"
                  text
                  class="mb-0"

                >
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{alert_message}}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>

              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-4"
                  @click="save_released_transmital"
                  v-if="!saving"
                >
                  Release Transmital
                </v-btn>
                <v-progress-circular
                  :size=50
                  :width="5"
                  color="primary"
                  indeterminate
                  v-if="saving"
                ></v-progress-circular>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="dialogs = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {mdiPrinter, mdiToggleSwitchOff, mdiToggleSwitch, mdiEyeCircle, mdiCancel} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import LoansApplication from '@/views/create_interface/LoansApplication'
  import LoansApplicationRequest from '@/views/create_interface/LoansApplicationRequest'
  import moment from "moment";

  export default {

    components: {
      snackBarDialog,
      LoansApplication,
      LoansApplicationRequest,
    },
    mounted() {
      const data = new FormData()
      data.append('branch_id', this.branch_id);
      this.retrieve_transaction_month(data)
        .then(response => {
          this.month_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    setup() {
      return {
        icons: {
          mdiPrinter,
          mdiToggleSwitchOff,
          mdiToggleSwitch,
          mdiEyeCircle,
          mdiCancel,
        },
      }
    },
    data() {
      return {

        selected_month_of: moment().format('MMMM DD, YYYY'),
        category: '',
        month_of: '',
        month_of_items: [],
        year_of_items: [],
        year_of: '',

        can_print: false,
        transmital_loans_items: [],
        loans_items: [],
        dialogs: false,
        view_dialog: false,
        member_id: 0,
        selected_transmital_no: '',
        co_maker_id: 0,
        mode_of_payment: '',
        terms: 0,
        amount: 0,
        share_capital: 0,
        regular_savings: 0,
        key: 0,
        id: 0,
        value: {},
        type_of_loan: '',
        specify_type_of_loan: '',
        purpose_of_loan: '',

        saving: false,
        alert: false,
        alert_message: '',

        cheque_no: '',
        cheque_date: '',
      }
    },
    computed: {
      ...mapGetters('authentication', ['branch_id', 'user_id', 'position', 'branch', 'branch_address', 'branch_contact_no']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('form_rules', ['rules']),
    },
    methods: {
      ...mapActions('transaction_months', ['retrieve_transaction_month', 'retrieve_all_transaction_year']),
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('loans_data', ['list_of_loans_transmital', 'released_generate_loan_transmital']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      total_loans_released(item) {
        var total_re = 0
        item.loans_data_apps.forEach(function (item) {
          total_re += item.amount
        })
        return total_re
      },
      view(item) {
        this.selected_transmital_no = item.id
        this.value = item
        this.view_dialog = true
        this.key += 1
        this.loans_items = item.loans_data_apps
      },
      for_released(item) {
        this.selected_transmital_no = item.id
        this.value = item
        this.dialogs = true
        this.key += 1
        this.loans_items = item.loans_data_apps
      },
      save_released_transmital() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('transmital_no', this.selected_transmital_no);
          data.append('cheque_date', this.cheque_date);
          data.append('cheque_no', this.cheque_no);
          this.released_generate_loan_transmital(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.dialogs = true
              this.cheque_date = ''
              this.cheque_no = ''
              this.list_of_loans_not_ye_approve()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      list_of_loans_not_ye_approve() {
        var indexs = this.month_of_items.map(function (x) {
          return x.id
        }).indexOf(this.month_of)
        if (indexs != -1) {
          const data = new FormData()
          data.append('branch_id', this.branch_id);
          data.append('date', this.month_of_items[indexs].month_of);
          this.list_of_loans_transmital(data)
            .then(response => {
              this.transmital_loans_items = response.data
              this.can_print = true
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      print_transmital(item) {
        var imgData = this.company_logo
        var array = []
        array.push(
          [
            {text: 'ID', alignment: 'center', style: 'label'},
            {text: 'TYPE OF LOAN', alignment: 'center', style: 'label'},
            {text: 'SPECIFY', alignment: 'center', style: 'label'},
            {text: 'NAME', alignment: 'center', style: 'label'},
            {text: 'LOAN AMOUNT', alignment: 'center', style: 'label'},
            {text: 'LOAN INSURANCE', alignment: 'center', style: 'label'},
            {text: 'LOAN PROCESSING', alignment: 'center', style: 'label'},
            {text: 'MORTUARY INSURANCE', alignment: 'center', style: 'label'},
            {text: 'FOUNDATION', alignment: 'center', style: 'label'},
            {text: 'NMAPI', alignment: 'center', style: 'label'},
            {text: 'SHARE CAPITAL', alignment: 'center', style: 'label'},
            {text: 'REG SAVINGS', alignment: 'center', style: 'label'},
            {text: 'NET PROCEEDS', alignment: 'center', style: 'label'},
            {text: 'SIGNATURE', alignment: 'center', style: 'label'},
          ]
        )
        if (item.loans_data_apps.length > 0) {
          var total = 0
          var total_insurance = 0
          var total_processing = 0
          var total_mortuary = 0
          var total_foundation = 0
          var total_nmapi = 0
          var total_share = 0
          var total_sav = 0
          var total_net = 0

          item.loans_data_apps.forEach(function (item, index, payment) {
            total += parseFloat(item.amount)
            total_insurance += parseFloat(item.loan_insurance)
            total_processing += parseFloat(item.processing_fee)
            total_mortuary += parseFloat(item.mortuary)
            total_foundation += parseFloat(item.foundation)
            total_nmapi += parseFloat(item.nmapi)
            total_share += parseFloat(item.share_capital)
            total_sav += parseFloat(item.regular_savings)
            var net_pro = parseFloat(item.amount) - (parseFloat(item.loan_insurance) + parseFloat(item.processing_fee) + parseFloat(item.mortuary) + parseFloat(item.foundation) + parseFloat(item.nmapi) + parseFloat(item.share_capital) + parseFloat(item.regular_savings))
            total_net += net_pro
            array.push(
              [
                {text: item.id, alignment: 'center'},
                {text: item.type_of_loan, alignment: 'left'},
                {text: item.specify_type_of_loan, alignment: 'left'},
                {text: item.members.last_name + ', ' + item.members.first_name, alignment: 'left'},
                {
                  text: (item.amount / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left'
                },
                {
                  text: (item.loan_insurance / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left'
                },
                {
                  text: (item.processing_fee / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left'
                },
                {
                  text: (item.mortuary / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left'
                },
                {
                  text: (item.foundation / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left'
                },
                {
                  text: (item.nmapi / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left'
                },
                {
                  text: (item.share_capital / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left'
                },
                {
                  text: (item.regular_savings / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left'
                },
                {
                  text: (net_pro / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left'
                },
                {text: '', alignment: 'center'},
              ]
            )
            if (Object.is(payment.length - 1, index)) {
              // execute last item logic
              array.push(
                [
                  {text: '', alignment: 'center'},
                  {text: '', alignment: 'left'},
                  {text: '', alignment: 'left'},
                  {text: 'TOTAL', alignment: 'left'},
                  {
                    text: (total / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'left'
                  }, {
                  text: (total_insurance / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left'
                }, {
                  text: (total_processing / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left'
                }, {
                  text: (total_mortuary / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left'
                }, {
                  text: (total_foundation / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left'
                }, {
                  text: (total_nmapi / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left'
                }, {
                  text: (total_share / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left'
                }, {
                  text: (total_sav / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left'
                },{
                  text: (total_net / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left'
                },
                  {text: '', alignment: 'center'},
                ]
              )
            }
          })
        } else {
          array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'landscape',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'LIST OF LOANS IN TRANSMITAL # ' + item.id, style: 'title'},
            '============================================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [20, 40, 40, 80, 40, 40, 40, 40, 40, 40, 40, 40, 40, 60],
                body: array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: 'Approved By: ', style: 'cashier_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: '', style: 'cashier_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: '_________________________________',style: 'cashier_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Coop Manager', style: 'bm_placeholder'},
                    {text: '', style: 'au_placeholder'},
                    {text: 'BOARD', style: 'au_placeholder'},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'General Manager', style: 'bm_placeholder'},
                    {text: 'Finance Committee', style: 'au_placeholder'},
                  ]
                },
              ]
            },
          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 7,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
      print_data() {
        var imgData = this.company_logo
        var array = []
        array.push(
          [
            {text: 'NO', alignment: 'center', style: 'label'},
            {text: 'DATE', alignment: 'center', style: 'label'},
            {text: 'TOTAL AMOUNT', alignment: 'center', style: 'label'},
            {text: 'STATUS', alignment: 'center', style: 'label'},
          ]
        )
        if (this.transmital_loans_items.length > 0) {
          this.transmital_loans_items.forEach(function (item, index) {
            var total_re = 0
            item.loans_data_apps.forEach(function (item) {
              total_re += item.amount
            })
            array.push(
              [
                {text: item.id, alignment: 'center'},
                {text: item.date, alignment: 'left'},
                {
                  text: (total_re / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left'
                },
                {
                  text: item.is_approved === 1 ? 'APPROVED' :
                    (item.is_approved === 0 ? 'NOT YET DONE' :
                      (item.is_approved === 2 ? 'NOT YET RELEASED FROM FINANCE' : '')),
                  alignment: 'left'
                },
                {text: status, alignment: 'center'},
              ]
            )
          })
        } else {
          array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'LIST OF LOANS TRANSMITAL', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [40, 60, 230, 70, 100],
                body: array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },
          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 7,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
